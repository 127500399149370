import { useState, useEffect } from 'react'
import { Router, nav } from 'tiny-react-router'
import { ToastContainer } from 'react-toastify'
import { Header } from './shared/components/Header'
import { Footer } from './shared/components/Footer'
import { createStore } from './shared/state'
import { 
  getUser,
  getUsers,
  getConfig,
  getRegions,
  getProducts,
  getProjects,
  getSolutions,
  getCompanies 
} from './shared/api'
import { Me } from './screens/Me'
import { Login } from './screens/Login'
import { Users } from './screens/Users'
import { Regions } from './screens/Regions'
import { Projects } from './screens/Projects'
import { Products } from './screens/Products'
import { Solutions } from './screens/Solutions'
import { Companies } from './screens/Companies'
import { MyProjects } from './screens/MyProjects'
import { ProjectEditor } from './screens/ProjectEditor'
import { SolutionEditor } from './screens/SolutionEditor'
import './shared/state'
import 'react-toastify/dist/ReactToastify.css'

const routes = {
  '/'              : MyProjects,
  '/me'            : Me,
  '/login'         : Login,
  '/users'         : Users,
  '/regions'       : Regions, 
  '/projects'      : Projects,
  '/projects/:id'  : ProjectEditor,
  '/products'      : Products,
  '/solutions'     : Solutions,
  '/solutions/:id' : SolutionEditor,
  '/companies'     : Companies
}

const State = createStore('state')

function App() {
  const [user,setUser] = useState(null)
  const [users,setUsers] = useState([])
  const [config, setConfig] = useState({})
  const [regions,setRegions] = useState([])
  const [projects, setProjects] = useState([])
  const [products, setProducts] = useState([])
  const [solutions, setSolutions] = useState([])
  const [companies, setCompanies] = useState([]) 

  const fetchAll = async () => {
    Promise.all([
      getUser(),
      getUsers(),
      getConfig(),
      getRegions(),
      getProjects(),
      getProducts(),
      getSolutions(),
      getCompanies()
    ]).then(([user, users, config, regions, projects, products, solutions, companies]) => {
      setUser(user)
      setUsers(users)
      setConfig(config)
      setRegions(regions)
      setProjects(projects)
      setProducts(products)
      setSolutions(solutions)
      setCompanies(companies)
      if (!user) nav('/login')
    })
  }

  useEffect(() => {
    (async () => {
      await fetchAll()
    })()
  }, [])

  return (
    <State.Provider value={{ 
      user, setUser,
      users, setUsers,
      config, setConfig,
      regions, setRegions,
      projects, setProjects,
      products, setProducts,
      solutions, setSolutions,
      companies, setCompanies,
      fetchAll 
    }}> 
      <div className="App">
        <Header />
        <Router routes={routes} />
        <Footer />
        <ToastContainer 
          autoClose={2000}
          pauseOnFocusLoss={false}   
        />
      </div>
    </State.Provider>
  )
}

export default App;
