import {
  CONTACT_PHONE,
  CONTACT_EMAIL
} from '../config.js'
import ProtanLogoWhite from '../../graphics/protan_logo_hvit.png'
import './Footer.css'

export const Footer = (props) => {
  return (
    <div className="Footer">
      <div className="logo">
        <img src={ProtanLogoWhite} alt="logowhite" />
      </div>
      <div className="meta">
        <div className="contact">
          <div className="bold">Protan AS</div>
          <div>Baches vei 1, 3413 Lier</div>
          <div><a href={`tel:${CONTACT_PHONE}`}>{CONTACT_PHONE}</a></div>
          <div><a href={`mailto:${CONTACT_EMAIL}`}>{CONTACT_EMAIL}</a></div>
        </div>
      </div>
    </div>
  )
}
