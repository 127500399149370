import { nav } from 'tiny-react-router'
import { logout } from '../../shared/api'
import { useStore } from '../../shared/state'
import logo from '../../graphics/logo.webp'
import './Header.css'

export const Header = (props) => {
  const { user, setUser } = useStore('state') 
  const handleLogoClick = () => {
    if (user) nav('/')
    else nav('/login')
  }
  const handleLogout = async () => {
    await logout()
    setUser(null)
    nav('/login')
  }
  return (
    <div className="Header">
      <div className="logo" onClick={handleLogoClick}>
        <img src={logo} alt="logo" />
      </div>
      { user?.administrator &&
      <div className="admin">
        <div className="users" onClick={() => nav('/companies')}>Selskap</div>
        <div className="users" onClick={() => nav('/regions')}>Regioner</div>
        <div className="users" onClick={() => nav('/users')}>Brukere</div>
        <div className="users" onClick={() => nav('/projects')}>Prosjekter</div>
        <div className="users" onClick={() => nav('/products')}>Produkter</div>
        <div className="users" onClick={() => nav('/solutions')}>Løsninger</div>
      </div>
      }
      { user &&
      <div className="user">
        <div className="username" onClick={() => nav('/me')}>{user?.email}</div>
        <div className="logout" onClick={handleLogout}>Logg ut</div>
      </div>
      }
    </div>
  )
}
