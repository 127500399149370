import { useMemo, useState } from 'react'
import { useTable } from 'react-table'
import Modal from 'react-modal'
import { nav } from 'tiny-react-router'
import { useStore } from '../../shared/state'
import './index.css'

Modal.setAppElement('#root')

export const Projects = (props) => {
  const {projects} = useStore('state') 
  const [showArchived, setShowArchived] = useState(false)
  const [searchFilter, setSearchFilter] = useState('')

  const columns = useMemo(
   () => [
     {
       Header: 'Navn',
       accessor: 'name', // accessor is the "key" in the data
     },
     {
       Header: 'Opprettet av',
       accessor: 'creatorname'
     },
     {
       Header: 'Selskap',
       accessor: 'companyname'
     },
     {
       Header: 'Region',
       accessor: 'regionname'
     },
   ],
   []
  )

  const data = projects
    .filter(p => p.archived !== !showArchived) 
    .filter(p => {
      if (searchFilter === '') return true
      let match = 
        p.name.toLowerCase().indexOf(searchFilter.toLowerCase()) >= 0 ||
        p.creatorname.toLowerCase().indexOf(searchFilter.toLowerCase()) >= 0 ||
        p.companyname.toLowerCase().indexOf(searchFilter.toLowerCase()) >= 0
      return match
    })
  const tableInstance = useTable({ columns, data })
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = tableInstance

  const handleToggleArchived = async () => {
    setShowArchived(!showArchived) 
  }

  return (
    <div className="MainBody Projects">
      <div className="top">
        <h1>Prosjekter</h1>
        <div className="search">
          <input type="text" value={searchFilter} placeholder="Søk" onChange={e => setSearchFilter(e.target.value)} />
        </div>
        <div className="buttons">
          <button className={showArchived ? 'selected' : ''} onClick={handleToggleArchived}>Arkiv</button>
        </div>
      </div>
      <div className="UserTable">
       <table {...getTableProps()}>
         <thead>
           {// Loop over the header rows
           headerGroups.map(headerGroup => (
             // Apply the header row props
             <tr {...headerGroup.getHeaderGroupProps()}>
               {// Loop over the headers in each row
               headerGroup.headers.map(column => (
                 // Apply the header cell props
                 <th {...column.getHeaderProps()}>
                   {// Render the header
                   column.render('Header')}
                 </th>
               ))}
             </tr>
           ))}
         </thead>
         {/* Apply the table body props */}
         <tbody {...getTableBodyProps()}>
           {// Loop over the table rows
           rows.map(row => {
             // Prepare the row for display
             prepareRow(row)
             return (
               // Apply the row props
               <tr {...row.getRowProps()} onClick={() => nav(`/projects/${row.original.id}`)}>
                 {// Loop over the rows cells
                 row.cells.map(cell => {
                   // Apply the cell props
                   return (
                     <td {...cell.getCellProps()}>
                       {// Render the cell contents
                       cell.render('Cell')}
                     </td>
                   )
                 })}
               </tr>
             )
           })}
         </tbody>
       </table>
      </div>
    </div>
  )
}
