/** LOGIN **/

export async function login(username, password) {
  const res = await fetch('/api/login', {
    method: 'POST',
    body: JSON.stringify({
      username: username,
      password: password
    }),
    headers: {
      'Content-Type': 'application/json'
    }
  })
  if (!res.ok) throw new Error('Unknown username or password') 
  const session = await res.json()
  return session
}

export async function newpassword(username) {
  const res = await fetch('/api/newpassword', {
    method: 'POST',
    body: JSON.stringify({
      username: username
    }),
    headers: {
      'Content-Type': 'application/json'
    }
  })
  if (!res.ok) throw new Error('Unable to get new password')
  return 'OK'
}

export async function logout() {
  const res = await fetch('/api/logout')
  return res.ok
}

/** USER **/

export async function getUser() {
  const res = await fetch('/api/me')
  if (!res.ok) return null
  const user = await res.json()
  return user
}

export async function setUser(payload) {
  const res = await fetch('/api/me', {
    method: 'PUT',
    body: JSON.stringify(payload),
    headers: {
      'Content-Type': 'application/json'
    }
  })
  if (!res.ok) throw new Error('Unable to update user') 
  const user = await res.json()
  return user
}

export async function getUsers() {
  const res = await fetch(`/api/users`)
  if (!res.ok) return []
  const user = await res.json()
  return user
}

export async function createUser(props) {
  const res = await fetch('/api/users', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(props)
  })
  if (!res.ok) {
    const msg = await res.text()
    throw new Error(msg)  
  }
  const user = await res.json()
  return user
}

export async function updateUser(props) {
  const res = await fetch(`/api/users/${props.id}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(props)
  })
  if (!res.ok) {
    const msg = await res.text()
    throw new Error(msg)  
  }
  const user = await res.json()
  return user 
}

export async function deleteUser(props) {
  const res = await fetch(`/api/users/${props.id}`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json'
    }
  })
  const msg = await res.text()
  if (!res.ok) throw new Error(msg)
  return msg
}

/** COMPANY **/

export async function getCompanies() {
  const res = await fetch(`/api/companies`)
  if (!res.ok) return [] 
  const companies = await res.json()
  return companies
}

export async function createCompany(props) {
  const res = await fetch('/api/companies', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(props)
  })
  if (!res.ok) {
    const msg = await res.text()
    throw new Error(msg)  
  }
  const company = await res.json()
  return company
}

export async function updateCompany(props) {
  const res = await fetch(`/api/companies/${props.id}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(props)
  })
  if (!res.ok) {
    const msg = await res.text()
    throw new Error(msg)  
  }
  const company = await res.json()
  return company
}

export async function deleteCompany(props) {
  const res = await fetch(`/api/companies/${props.id}`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json'
    }
  })
  const msg = await res.text()
  if (!res.ok) throw new Error(msg)
  return msg
}

/** PROJECTS **/

export async function getProjects() {
  const res = await fetch(`/api/projects`)
  if (!res.ok) return [] 
  const projects = await res.json()
  return projects
}

export async function createProject(props) {
  const res = await fetch('/api/projects', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(props)
  })
  if (!res.ok) return null
  const project = await res.json()
  return project
}

export async function updateProject(props) {
  const res = await fetch(`/api/projects/${props.id}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(props)
  })
  if (!res.ok) return null
  const project = await res.json()
  return project
}

/** REGIONS **/

export async function getRegions() {
  const res = await fetch(`/api/regions`)
  if (!res.ok) return [] 
  const regions = await res.json()
  return regions 
}

export async function createRegion(props) {
  const res = await fetch('/api/regions', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(props)
  })
  if (!res.ok) {
    const msg = await res.text()
    throw new Error(msg)  
  }
  const region = await res.json()
  return region
}

export async function deleteRegion(props) {
  const res = await fetch(`/api/regions/${props.id}`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json'
    }
  })
  const msg = await res.text()
  if (!res.ok) throw new Error(msg)  
  return msg
}

export async function updateRegion(props) {
  const res = await fetch(`/api/regions/${props.id}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(props)
  })
  if (!res.ok) {
    const msg = await res.text()
    throw new Error(msg)  
  }
  const region = await res.json()
  return region
}

/** Products **/

export async function getProducts() {
  const res = await fetch(`/api/products`)
  if (!res.ok) return [] 
  const products = await res.json()
  return products 
}

export async function createProduct(props) {
  const res = await fetch('/api/products', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(props)
  })
  if (!res.ok) {
    const msg = await res.text()
    throw new Error(msg)  
  }
  const product = await res.json()
  return product
}

export async function updateProduct(props) {
  const res = await fetch(`/api/products/${props.id}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(props)
  })
  if (!res.ok) {
    const msg = await res.text()
    throw new Error(msg)  
  }
  const company = await res.json()
  return company
}

export async function deleteProduct(props) {
  const res = await fetch(`/api/products/${props.id}`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json'
    }
  })
  const msg = await res.text()
  if (!res.ok) throw new Error(msg)
  return msg
}

/** Solutions **/

export async function getSolutions() {
  const res = await fetch(`/api/solutions`)
  if (!res.ok) return [] 
  const solutions = await res.json()
  return solutions 
}

export async function createSolution(props) {
  const res = await fetch('/api/solutions', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(props)
  })
  if (!res.ok) return null
  const solution = await res.json()
  return solution
}

export async function deleteSolution(props) {
  await fetch(`/api/solutions/${props.id}`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

export async function updateSolution(props) {
  const res = await fetch(`/api/solutions/${props.id}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(props)
  })
  if (!res.ok) return null
  const company = await res.json()
  return company
}

/** CONFIG **/

export async function getConfig() {
  const res = await fetch('/api/config')
  if (!res.ok) return null
  const config = await res.json()
  return config
}
