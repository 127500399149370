import { useState } from 'react'
import { toast } from 'react-toastify'
import { useStore } from '../../shared/state'
import { setUser } from '../../shared/api'
import './index.css'

export const Me = (props) =>  {
  const { user } = useStore('state') 
  const [ newpass, setNewpass ] = useState('')
  const [ changepass, setChangepass ] = useState(false)

  const handleSubmitPassForm = async (e) => {
    e.preventDefault()
    if (newpass === '') toast.error('Blankt passord ikke tillatt')
    if (newpass.length < 5) toast.error('Minimum 5 karakterer')
    const update = async () => {
      await setUser({ password: newpass })
      setChangepass(false)
    }
    toast.promise(
      update(), 
      {
        pending: 'Oppdaterer passord',
        success: 'Passord oppdatert', 
        error: 'Noe gikk galt'
      }
    )
  }

  const changepassText = changepass ? 'Tilbake' : 'Endre passord'

  return (
    <div className="MainBody Me">
      <div className="top">
        <h1>Min bruker</h1>
        <div className="buttons">
          <button onClick={() => setChangepass(!changepass)}>{changepassText}</button>
        </div>
      </div>
      { !changepass &&
      <div className="userInfo">
        <div className="userInfoElement">
          <div className="key">Navn:</div>
          <div className="value">{user?.name}</div>
        </div>
        <div className="userInfoElement">
          <div className="key">Epost:</div>
          <div className="value">{user?.email}</div>
        </div>
        <div className="userInfoElement">
          <div className="key">Telefon:</div>
          <div className="value">{user?.phone}</div>
        </div>
        <div className="userInfoElement">
          <div className="key">Selskap:</div>
          <div className="value">{user?.companyname}</div>
        </div>
      </div>
      }
      { changepass &&
      <form className="passform" onSubmit={handleSubmitPassForm}>
        <input type="password" placeholder="Nytt passord" value={newpass} onChange={e => setNewpass(e.target.value)} />
        <button type="submit">Endre</button>
      </form>
      }
    </div>
  )
}
