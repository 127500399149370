import './Input.css'

export const Input = (props) => {

  return (
    <div className="InputContainer">
      <label className={!!props.value ? 'visible' : ''}>{props.placeholder || 'Missing placeholder'}</label>
      <input {...props} />
    </div>
  )
}
