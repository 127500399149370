import { useMemo, useState } from 'react'
import { useTable } from 'react-table'
import { format } from 'date-fns'
import Modal from 'react-modal'
import { nav } from 'tiny-react-router'
import { useStore } from '../../shared/state'
import { DATE_FORMAT } from '../../shared/config'
import './index.css'

Modal.setAppElement('#root')

export const Solutions = (props) => {
  const { solutions } = useStore('state') 
  const [showArchived, setShowArchived] = useState(false)

  const columns = useMemo(
   () => [
     {
       Header: 'Navn',
       accessor: 'name', // accessor is the "key" in the data
     },
     {
       Header: 'Sti',
       accessor: (row, index) => {
        return `${row?.data?.stepValues.map(s => s.label).join(' -> ') || ''}` 
      } 
     },
     {
       Header: 'Produkter',
       accessor: (row, index) => {
        return `${row?.products.map(p => `${p.name} (${p.category})`).join(', ') || ''}` 
      } 
     },
     {
       Header: 'Opprettet',
       accessor: (row, index) => {
        return format(new Date(row.created), DATE_FORMAT)
      } 
     },
     {
       Header: 'Oppdatert',
       accessor: (row, index) => {
        return format(new Date(row.updated), DATE_FORMAT)
      } 
     },
   ],
   []
  )

  const data = solutions.filter(u => u.archived !== !showArchived) 
  const tableInstance = useTable({ columns, data })
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = tableInstance

  const handleToggleArchived = async () => {
    setShowArchived(!showArchived) 
  }

  return (
    <div className="MainBody Solutions">
      <div className="top">
        <h1>Løsninger</h1>
        <div className="buttons">
          <button className={showArchived ? 'selected' : ''} onClick={handleToggleArchived}>Arkiv</button>
          <button onClick={() => nav(`/solutions/new`)}>Legg til</button>
        </div>
      </div>
      <div className="companyTable">
       <table {...getTableProps()}>
         <thead>
           {// Loop over the header rows
           headerGroups.map(headerGroup => (
             // Apply the header row props
             <tr {...headerGroup.getHeaderGroupProps()}>
               {// Loop over the headers in each row
               headerGroup.headers.map(column => (
                 // Apply the header cell props
                 <th {...column.getHeaderProps()}>
                   {// Render the header
                   column.render('Header')}
                 </th>
               ))}
             </tr>
           ))}
         </thead>
         {/* Apply the table body props */}
         <tbody {...getTableBodyProps()}>
           {// Loop over the table rows
           rows.map(row => {
             // Prepare the row for display
             prepareRow(row)
             return (
               // Apply the row props
               <tr {...row.getRowProps()} onClick={() => nav(`/solutions/${row.original.id}`)}>
                 {// Loop over the rows cells
                 row.cells.map(cell => {
                   // Apply the cell props
                   return (
                     <td {...cell.getCellProps()}>
                       {// Render the cell contents
                       cell.render('Cell')}
                     </td>
                   )
                 })}
               </tr>
             )
           })}
         </tbody>
       </table>
      </div>
    </div>
  )
}
