import { useState } from 'react'
import { nav } from 'tiny-react-router'
import { toast } from 'react-toastify'
import { useStore } from '../../shared/state'
import { login, newpassword } from '../../shared/api'
import './index.css'

export const Login = (props) => {
  const { fetchAll } = useStore('state')
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [showForgot, setShowForgot] = useState(false)

  const handleSubmit = async (e) => {
    e.preventDefault()
    if (showForgot)
      await handleNewPassword()
    else
      await handleLogin()
  }

  const handleLogin = async () => {
    const loginAndFetch = async () => {
      await login(username, password)
      await fetchAll()
      nav('/')
    }
    toast.promise(
      loginAndFetch(),
      {
        pending: 'Logger på',
        success: 'Logget på', 
        error: 'Feil brukernavn eller passord'
      }
    )
  }

  const handleNewPassword = async () => {
    toast.promise(
      newpassword(username), 
      {
        pending: 'Sender epost',
        success: {
          render({ data }) { 
            setShowForgot(false)
            return 'Epost sendt' 
          }
        },
        error: 'Ukjent brukernavn'
      }
    )
  }

  const forgotPasswordButtonText = showForgot ? 'Tilbake' : 'Glemt passord'
  const loginButtonText = showForgot ? 'Send epost' : 'Logg inn'

  return (
    <div className="MainBody Login">
      <h1>Prosjektkalkulator B2B</h1>
      <div className="box">
        <h2>Logg inn</h2>
        <form onSubmit={handleSubmit}>
          <input type="text" placeholder="Brukernavn" value={username} onChange={e => setUsername(e.target.value)} />
          { !showForgot &&
            <input type="password" placeholder="Passord" value={password} onChange={e => setPassword(e.target.value)} /> 
          }
          <div className="buttons">
            <div className="forgotPassword" onClick={() => setShowForgot(!showForgot)}>{forgotPasswordButtonText}</div>
            <button type="submit">{loginButtonText}</button>
          </div>
        </form>
      </div>
    </div>
  )
}
