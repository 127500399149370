import { useMemo, useState } from 'react'
import { useTable } from 'react-table'
import { format } from 'date-fns'
import { toast } from 'react-toastify'
import Modal from 'react-modal'
import { useStore } from '../../shared/state'
import { DATE_FORMAT, PAASLAG } from '../../shared/config'
import { 
  createRegion,
  updateRegion,
  deleteRegion 
} from '../../shared/api'
import { Input } from '../../shared/components/Input'
import './index.css'

Modal.setAppElement('#root')

export const Regions = (props) => {
  const {regions, setRegions} = useStore('state') 
  const [showModal, setShowModal] = useState(false)
  const [id, setId] = useState(0)
  const [name, setName] = useState('')
  const [adding, setAdding] = useState(false)
  const [markup, setMarkup] = useState({})
  const [archived, setArchived] = useState(false)
  const [showArchived, setShowArchived] = useState(false)

  const columns = useMemo(
   () => [
     {
       Header: 'Navn',
       accessor: 'name', // accessor is the "key" in the data
     },
     {
       Header: PAASLAG.protan.label, 
       accessor: (row, index) => {
        return `${row?.markup?.protan || ''}` 
      } 
     },
     {
       Header: PAASLAG.glava.label, 
       accessor: (row, index) => {
        return `${row?.markup?.glava || ''}` 
      } 
     },
     {
       Header: PAASLAG.sunde.label,
       accessor: (row, index) => {
        return `${row?.markup?.sunde || ''}` 
      } 
     },
     {
       Header: 'Opprettet',
       accessor: (row, index) => {
        return format(new Date(row.created), DATE_FORMAT)
      } 
     },
     {
       Header: 'Oppdatert',
       accessor: (row, index) => {
        return format(new Date(row.updated), DATE_FORMAT)
      } 
     },
   ],
   []
  )

  const data = regions.filter(u => u.archived !== !showArchived) 
  const tableInstance = useTable({ columns, data })
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = tableInstance

  const handleAddCompany = async () => {
    setShowModal(true)
    setId(0)
    setName('')
    setAdding(true)
    setMarkup({})
  }

  const closeModal = () => {
    setShowModal(false)
    setId(0)
    setName('')
    setAdding(false)
    setMarkup({})
  }

  const handleEditCompany = (company) => {
    setShowModal(true)
    setId(company.id)
    setName(company.name)
    setAdding(false)
    setMarkup(company.markup)
    setArchived(company.archived)
  }

  const validateRegion = () => {
    let valid = true
    if (!name || name === '') {
      valid=false
      toast.error('Navn påkrevd')
    }
    let markup_valid = true
    if (isNaN(parseInt(markup['glava']))) markup_valid = false
    if (isNaN(parseInt(markup['sunde']))) markup_valid = false
    if (isNaN(parseInt(markup['protan']))) markup_valid = false
    if (!markup_valid) {
      valid = false
      toast.error('Fraktpåslag må være et heltall')
    }
    return valid
  }

  const handleCreateRegion = async () => {
    if (!validateRegion()) return
    const update = async () => {
      const region = await createRegion({ name, markup, archived })
      setRegions([region].concat(regions))
    }
    toast.promise(
      update(), 
      {
        pending: 'Oppretter region',
        success: 'Region opprettet',
        error: 'Noe gikk galt'
      }
    )
    closeModal()
  }

  const handleUpdateRegion = async () => {
    if (!validateRegion()) return
    const update = async () => {
      const region = await updateRegion({ id, name, markup, archived })
      setRegions(regions.map(c => {
        if (c.id === id) return region 
        return c
      }))
    }
    toast.promise(
      update(), 
      {
        pending: 'Oppdaterer region',
        success: 'Region oppdatert',
        error: 'Noe gikk galt'
      }
    )
    closeModal()
  }

  const handleDeleteRegion = async () => {
    const confirmed = window.confirm('Sikker?')
    if (!confirmed) return
    const update = async () => {
      await deleteRegion({ id })
      setRegions(regions.filter(c => c.id !== id))
    }
    toast.promise(
      update(), 
      {
        pending: 'Sletter region',
        success: 'Region slettet',
        error: 'Noe gikk galt'
      }
    )
    closeModal()
  }

  const handleToggleArchived = async () => {
    setShowArchived(!showArchived) 
  }

  return (
    <div className="MainBody Regions">
      <Modal 
        isOpen={showModal}
        onRequestClose={closeModal}
      >
        <div className="RegionsModalInner">
          <h1>Legg til region</h1>
          <div className="form">
            <Input type="text" placeholder="Navn på region" value={name} onChange={(e) => setName(e.target.value)} />
            <Input type="number" placeholder={PAASLAG.protan.label} value={markup?.protan} onChange={(e) => setMarkup(Object.assign({}, markup, { protan : e.target.value }))} />
            <Input type="number" placeholder={PAASLAG.glava.label} value={markup?.glava} onChange={(e) => setMarkup(Object.assign({}, markup, { glava: e.target.value }))} />
            <Input type="number" placeholder={PAASLAG.sunde.label} value={markup?.sunde} onChange={(e) => setMarkup(Object.assign({}, markup, { sunde: e.target.value }))} />
            <div className="checkbox">
              <input type="checkbox" placeholder="Arkivert" checked={archived} onChange={(e) => setArchived(e.target.checked)} />
              <label>Arkivert</label>
            </div>
          </div>
          <div className="buttons">
            <button onClick={closeModal}>Avbryt</button>
            <div>
            { !adding &&
            <button className="delete" onClick={handleDeleteRegion}>Slett</button>
            }
            <button onClick={adding ? handleCreateRegion : handleUpdateRegion}>Lagre</button>
            </div>
          </div>
        </div>
      </Modal>
      <div className="top">
        <h1>Regioner</h1>
        <div className="buttons">
          <button className={showArchived ? 'selected' : ''} onClick={handleToggleArchived}>Arkiv</button>
          <button onClick={handleAddCompany}>Legg til</button>
        </div>
      </div>
      <div className="companyTable">
       <table {...getTableProps()}>
         <thead>
           {// Loop over the header rows
           headerGroups.map(headerGroup => (
             // Apply the header row props
             <tr {...headerGroup.getHeaderGroupProps()}>
               {// Loop over the headers in each row
               headerGroup.headers.map(column => (
                 // Apply the header cell props
                 <th {...column.getHeaderProps()}>
                   {// Render the header
                   column.render('Header')}
                 </th>
               ))}
             </tr>
           ))}
         </thead>
         {/* Apply the table body props */}
         <tbody {...getTableBodyProps()}>
           {// Loop over the table rows
           rows.map(row => {
             // Prepare the row for display
             prepareRow(row)
             return (
               // Apply the row props
               <tr {...row.getRowProps()} onClick={() => handleEditCompany(row.original)}>
                 {// Loop over the rows cells
                 row.cells.map(cell => {
                   // Apply the cell props
                   return (
                     <td {...cell.getCellProps()}>
                       {// Render the cell contents
                       cell.render('Cell')}
                     </td>
                   )
                 })}
               </tr>
             )
           })}
         </tbody>
       </table>
      </div>
    </div>
  )
}
