import { useState, useEffect } from 'react'
import { nav } from 'tiny-react-router'
import { toast } from 'react-toastify'
import Modal from 'react-modal'
import { format } from 'date-fns'
import html2pdf from 'html2pdf.js' 
import { 
  createProject,
  updateProject 
} from '../../shared/api'
import { useStore } from '../../shared/state'
import {
  DATE_FORMAT,
  CONTACT_PHONE,
  CONTACT_EMAIL,
  SELECTOR_CHOICES
} from '../../shared/config.js'
import { formatNum } from '../../shared/utils.js'
import MineralUllImage from '../../graphics/mineralull.png'
import SandWitchImage from '../../graphics/sandwitch.png'
import { tablestyle, exportstyle } from './index.css.js'
import './index.css'

Modal.setAppElement('#root')

export const ProjectEditor = (props) => {
  const { user, config, products, solutions, projects, companies, regions, setProjects } = useStore('state')
  // eslint-disable-next-line 
  let project = projects.filter(p => p.id == props.id)[0]
  if (!project) project = {
    id: 0,
    name: 'Nytt prosjekt',
    customer: '',
    region: 0,
    data: {
      area: 0,
      step: 0,
      drains: 0,
      pheight: 500,
      bushings: 0,
      perimeter: 0,
      stepValues: []
    },
    archived: false,
  }

  const [name, setName] = useState(project.name)
  const [step, setStep] = useState(project.data.step)
  const [area, setArea] = useState(project.data.area)
  const [drains, setDrains] = useState(project.data.drains)
  const [region, setRegion] = useState(project.region)
  const [pheight, setPHeight] = useState(project.data.pheight)
  const [bushings, setBushings] = useState(project.data.bushings)
  const [customer, setCustomer] = useState(project.customer)
  const [archived, setArchived] = useState(project.archived)
  const [perimeter, setPerimeter] = useState(project.data.perimeter)
  const [stepValues, setStepValues] = useState(project.data.stepValues)
  const [showAllOptionsForSteps, setShowAllOptionForSteps] = useState([])
  const [showGuideModal, setShowGuideModal] = useState(false)

  //console.log(project, archived)

  useEffect(() => {
    setName(project.name)
    setStep(project.data.step)
    setArea(project.data.area)
    setRegion(project.region)
    setDrains(project.data.drains)
    setPHeight(project.data.pheight)
    setBushings(project.data.bushings)
    setCustomer(project.customer)
    setPerimeter(project.data.perimeter)
    setStepValues(project.data.stepValues)
    setArchived(project.archived)
  // eslint-disable-next-line
  }, [projects, props.id])

  useEffect(() => {
    const drains = Math.ceil(area / 250)
    const bushings = Math.ceil(area / 500)
    setDrains(drains)
    setBushings(bushings)
  }, [area])

  const handleShowGuideModal = () => {
    setShowGuideModal(true)
  }

  const handleCloseGuideModal = () => {
    setShowGuideModal(false)
  }

  const handleStepHeaderClick = (stepIndex) => {
    if (stepIndex >= step) return
    let _showAllOptionsForSteps = [].concat(showAllOptionsForSteps)
    if (_showAllOptionsForSteps.indexOf(stepIndex) >= 0)
      _showAllOptionsForSteps = _showAllOptionsForSteps.filter(s => s !== stepIndex)
    else
      _showAllOptionsForSteps.push(stepIndex)
    setShowAllOptionForSteps(_showAllOptionsForSteps)
  }

  const handleStepSelect = (stepIndex, option) => {
    let _stepValues = stepValues
    _stepValues[stepIndex] = option
    _stepValues = _stepValues.filter((s,i) => i <= stepIndex)
    setStepValues(_stepValues)
    if (stepIndex+1 < SELECTOR_CHOICES.length)
      setStep(stepIndex+1)
  }

  const validateProjectData = (project) => {
    let valid = true
    // eslint-disable-next-line
    if (project.region == 0) { valid = false; toast('Velg region', { type: 'warning' }) }
    return valid
  }

  const handleSaveProject = async () => {
    const updates = {
      name: name,
      region: region,
      customer: customer,
      data: {
        step: step,
        area: area,
        drains: drains,
        pheight: pheight,
        bushings: bushings,
        perimeter: perimeter,
        stepValues: stepValues
      },
      archived: archived
    }
    if (!validateProjectData(updates)) return
    const updated = Object.assign({}, project, updates)
    if (updated.id === 0) {
      delete updated.id
      updated.creator = user.id
      updated.company = user.company
      toast.promise(
        createProject(updated),
        {
          pending: 'Oppretter prosjekt',
          success: {
            render({ data }) { 
              setProjects([].concat(projects, [data]))
              nav(`/projects/${data.id}`)
              return 'Prosjekt opprettet' 
            }
          },
          error: 'Noe gikk galt'
        }
      )
    } else {
      delete updated.creatoremail
      delete updated.creatorname
      delete updated.companyname
      delete updated.regionname
      toast.promise(
        updateProject(updated),
        {
          pending: 'Oppdaterer prosjekt',
          success: {
            render({ data }) { 
              setProjects(projects.map(p => p.id === data.id ? data : p))
              return 'Prosjekt lagret' 
            }
          },
          error: 'Noe gikk galt'
        }
      )
    }
  }

  const handleSavePDF = async ({ includePrice }) => {
    const content = document.getElementById('calculation')
    const contentClone = content.cloneNode(true)
    contentClone.id = "pdfclone"
    // Create a <style> tag and append your CSS string
    const styleTag = document.createElement('style')
    styleTag.type = 'text/css'
    styleTag.appendChild(document.createTextNode(exportstyle({ includePrice })))
    // Append the <style> tag to your content
    contentClone.appendChild(styleTag)
    var opt = {
      margin:       1,
      filename:     `${name}-${includePrice ? 'price' : 'noprice'}.pdf`,
      image:        { type: 'jpeg', quality: 0.98 },
      html2canvas:  { scale: 4 },
      jsPDF:        { unit: 'cm', format: 'A4', orientation: 'portrait' }
    };
    html2pdf().set(opt).from(contentClone).save()
  }

  const selectorBoxes = SELECTOR_CHOICES.map((c,i) => {
    const hasValue = stepValues[i] !== undefined
    const openMenu = showAllOptionsForSteps.indexOf(i) >= 0
    const selectPath = stepValues.filter((v,_i) => _i < i).map(v => v.value).join(',')
    const options = c.options
      .filter(o => {
        if (o.filter && o.filter.indexOf(selectPath) >= 0) return false
        if (openMenu) return true
        if (hasValue && stepValues[i]?.value !== o.value) return false
        return true
      })
      .map(o => {
        const selected = stepValues[i] && stepValues[i].value === o.value
        return (
          <div key={o.value} className={`option ${selected ? 'selected' : ''}`} onClick={() => handleStepSelect(i, o)}>{`- ${o.label}`}</div>
        )
      })
    return (
      <div key={c.id}>
        <div className={`selectorBox ${(!hasValue && step === i) || openMenu ? 'open' : 'closed'}`}>
          <div className="name" onClick={() => handleStepHeaderClick(i)}>{c.label}</div>
          { SELECTOR_CHOICES[step].id >= c.id &&
            <>
            {options}
            </>
          }
        </div>
      </div>
    )
  })

  const regionOptions = regions.map(r => {
    return (<option key={r.id} value={r.id}>{r.name}</option>)
  })

  const solution = solutions.filter(s => s?.data?.stepValues.map(sv => sv.value).join('_') === stepValues.map(sv => sv.value).join('_'))[0]
  let isMineralull = (solution && solution?.data?.stepValues[2]?.value === 'C1')
  let isSandwitch = (solution && solution?.data?.stepValues[2]?.value === 'C3')
  let isolationProducts = []
  let isolationProductCost = 0
  let isolationProductAmount = area
  let firesectionProducts = []
  let firesectionProductCost = 0
  let firesectionProductAmount = perimeter*0.6
  let tekkeProducts = []
  let tekkeProductCost = 0
  let tekkeProductAmount = area
  let parapetProducts = []
  let parapetProductCost = 0
  let parapetProductAmount = perimeter * (pheight/1000)
  let slukProducts = []
  let slukProductCost = 0
  let slukProductAmount = drains 
  let kileProducts = []
  let kileProductCost = 0
  let kileProductAmount = drains * 2 
  let pipedetailProducts = []
  let pipedetailProductCost = 0
  let pipedetailProductAmount = bushings 
  let rigCost = 0
  let finalCost = 0
  let tariff = config?.tariff || 0
  let transportCostMap = {
    'sunde': config?.transport_base_sunde || 0,
    'glava': config?.transport_base_rockwool || 0,
    'protan': config?.transport_base_protan || 0,
  }
  let creating = project.id === 0

  if (solution) {
    let region = regions.filter(r => r.id === project.region)[0]
    let company = companies.filter(c => c.id === project.company)[0]
    if (creating) company = companies.filter(c => c.id === user.company)[0] // We dont have a company yet, so we used the creating users company
    isolationProducts = solution.products.filter(p => p.category.toLowerCase() === 'isolasjon').map(sp => products.find(p => p.id === sp.id))
    tekkeProducts = solution.products.filter(p => p.category.toLowerCase() === 'tekking').map(sp => products.find(p => p.id === sp.id))
    let festemiddelProducts = solution.products.filter(p => p.category.toLowerCase() === 'festemiddel').map(sp => products.find(p => p.id === sp.id))
    tekkeProducts = tekkeProducts.concat(festemiddelProducts)
    slukProducts = solution.products.filter(p => p.category.toLowerCase() === 'sluk').map(sp => products.find(p => p.id === sp.id))
    kileProducts = solution.products.filter(p => p.category.toLowerCase() === 'slukpakke').map(sp => products.find(p => p.id === sp.id))
    parapetProducts = solution.products.filter(p => p.category.toLowerCase() === 'parapet').map(sp => products.find(p => p.id === sp.id))
    pipedetailProducts = solution.products.filter(p => p.category.toLowerCase() === 'rørdetalj').map(sp => products.find(p => p.id === sp.id))
    firesectionProducts = solution.products.filter(p => p.category.toLowerCase() === 'brannseksjonering').map(sp => products.find(p => p.id === sp.id))
    if (isolationProducts.length > 0) {
      if (isSandwitch) isolationProductAmount = isolationProductAmount - firesectionProductAmount
      isolationProducts.forEach(isolationProduct => {
        let region_markup = region?.markup[isolationProduct.supplier]
        let company_markup = company.discount
        let _isolationProductCost = 0
        let transportCost = transportCostMap[isolationProduct.supplier] || 0
        _isolationProductCost = _isolationProductCost + parseFloat(isolationProduct.cost) + transportCost 
        _isolationProductCost = _isolationProductCost + _isolationProductCost * (region_markup/100)
        _isolationProductCost = _isolationProductCost + _isolationProductCost * (company_markup/100) 
        _isolationProductCost = _isolationProductCost + tariff * parseFloat(isolationProduct.work_cost)
        isolationProductCost += _isolationProductCost
      })
    }
    if (isSandwitch && firesectionProducts.length > 0) {
      firesectionProducts.forEach(firesectionProduct => {
        let region_markup = region?.markup[firesectionProduct.supplier]
        let company_markup = company.discount
        let _firesectionProductCost = 0
        let transportCost = transportCostMap[firesectionProduct.supplier] || 0
        _firesectionProductCost = _firesectionProductCost + parseFloat(firesectionProduct.cost) + parseFloat(firesectionProduct.transport_cost) * transportCost
        _firesectionProductCost = _firesectionProductCost + _firesectionProductCost * (region_markup/100)
        _firesectionProductCost = _firesectionProductCost + _firesectionProductCost * (company_markup/100) 
        _firesectionProductCost = _firesectionProductCost + tariff * parseFloat(firesectionProduct.work_cost)
        firesectionProductCost += _firesectionProductCost
      })
    }
    if (tekkeProducts.length > 0) {
      tekkeProducts.forEach(tekkeProduct => {
        let region_markup = region?.markup[tekkeProduct.supplier]
        let company_markup = company.discount
        let _tekkeProductCost = 0
        let transportCost = transportCostMap[tekkeProduct.supplier] || 0
        _tekkeProductCost = _tekkeProductCost + parseFloat(tekkeProduct.cost) + parseFloat(tekkeProduct.transport_cost) * transportCost
        _tekkeProductCost = _tekkeProductCost + _tekkeProductCost * (region_markup/100)
        _tekkeProductCost = _tekkeProductCost + _tekkeProductCost * (company_markup/100) 
        _tekkeProductCost = _tekkeProductCost + tariff * parseFloat(tekkeProduct.work_cost)
        tekkeProductCost += _tekkeProductCost
      })
    }
    if (parapetProducts.length > 0) {
      parapetProducts.forEach(parapetProduct => {
        let region_markup = region?.markup[parapetProduct.supplier]
        let company_markup = company.discount
        let _parapetProductCost = 0
        let transportCost = transportCostMap[parapetProduct.supplier] || 0
        _parapetProductCost = _parapetProductCost + parseFloat(parapetProduct.cost) + parseFloat(parapetProduct.transport_cost) * transportCost
        _parapetProductCost = _parapetProductCost + _parapetProductCost * (region_markup/100)
        _parapetProductCost = _parapetProductCost + _parapetProductCost * (company_markup/100) 
        _parapetProductCost = _parapetProductCost + tariff * parseFloat(parapetProduct.work_cost)
        parapetProductCost += _parapetProductCost
      })
    }
    if (slukProducts.length > 0) {
      slukProducts.forEach(slukProduct => {
        let region_markup = region?.markup[slukProduct.supplier]
        let company_markup = company.discount
        let _slukProductCost = 0
        let transportCost = transportCostMap[slukProduct.supplier] || 0
        _slukProductCost = _slukProductCost + parseFloat(slukProduct.cost) + parseFloat(slukProduct.transport_cost) * transportCost
        _slukProductCost = _slukProductCost + _slukProductCost * (region_markup/100)
        _slukProductCost = _slukProductCost + _slukProductCost * (company_markup/100) 
        _slukProductCost = _slukProductCost + tariff * parseFloat(slukProduct.work_cost)
        slukProductCost += _slukProductCost
      })
    }
    if (kileProducts.length > 0) {
      kileProducts.forEach(kileProduct => {
        let region_markup = region?.markup[kileProduct.supplier]
        let company_markup = company.discount
        let _kileProductCost = 0
        let transportCost = transportCostMap[kileProduct.supplier] || 0
        _kileProductCost = _kileProductCost + parseFloat(kileProduct.cost) + parseFloat(kileProduct.transport_cost) * transportCost
        _kileProductCost = _kileProductCost + _kileProductCost * (region_markup/100)
        _kileProductCost = _kileProductCost + _kileProductCost * (company_markup/100) 
        _kileProductCost = _kileProductCost + tariff * parseFloat(kileProduct.work_cost)
        kileProductCost += _kileProductCost
      })
    }
    if (pipedetailProducts.length > 0) {
      pipedetailProducts.forEach(pipedetailProduct => {
        let region_markup = region?.markup[pipedetailProduct.supplier]
        let company_markup = company.discount
        let _pipedetailProductCost = 0
        let transportCost = transportCostMap[pipedetailProduct.supplier] || 0
        _pipedetailProductCost = _pipedetailProductCost + parseFloat(pipedetailProduct.cost) + parseFloat(pipedetailProduct.transport_cost) * transportCost
        _pipedetailProductCost = _pipedetailProductCost + _pipedetailProductCost * (region_markup/100)
        _pipedetailProductCost = _pipedetailProductCost + _pipedetailProductCost * (company_markup/100) 
        _pipedetailProductCost = _pipedetailProductCost + tariff * parseFloat(pipedetailProduct.work_cost)
        pipedetailProductCost += _pipedetailProductCost
      })
    }
    finalCost = (isolationProductCost * isolationProductAmount) + 
                (tekkeProductCost * tekkeProductAmount) + 
                (parapetProductCost * parapetProductAmount) + 
                (slukProductCost * slukProductAmount) + 
                (kileProductCost * kileProductAmount) + 
                (pipedetailProductCost * pipedetailProductAmount) +
                (firesectionProductCost * firesectionProductAmount)
    rigCost = finalCost * 0.1 // NOTE: Endret til 10% etter sammenlikning med Prokalk løsninger sammed med Eivind
    //rigCost = rigCost + rigCost * (company.discount/100) // NOTE: Tas bort etter forslag fra Krister 
    finalCost = finalCost + rigCost
  }

  let SolutionImage = () => null
  if (isMineralull) SolutionImage = () => <img src={MineralUllImage} alt='mineralull' />
  if (isSandwitch) SolutionImage = () => <img src={SandWitchImage} alt='sandwitch' />
  if (!solution) SolutionImage = () => (
    <div className="nosolutionimage">
      <div>Fant ingen passende løsning.</div>
      <div>Ta kontakt på telefon <a href={`tel:${CONTACT_PHONE}`}>{CONTACT_PHONE}</a> eller epost <a href={`mailto:${CONTACT_EMAIL}`}>{CONTACT_EMAIL}</a>.</div>
    </div>
  )

  const creator_name = project?.creatorname || user?.name || ''
  const creator_company_name = project?.companyname || user?.companyname || ''
  const creator_email = project?.creatoremail || user?.email || ''

  return (
    <div className="MainBody ProjectEditor">
      <div className="userinfo">
        <div>{creator_name}</div>
        <div>{creator_company_name}</div>
        <div>{creator_email}</div>
      </div>
      <div className="label">
        <h2>{name}</h2>
      </div>
      <div className="selector">
        <div className="selectorLine">
          <div><div></div><div></div></div>
          <div><div></div><div></div></div>
          <div><div></div><div></div></div>
          <div><div></div><div></div></div>
          <div><div></div><div></div></div>
        </div>
        <div className="selectorBoxes">
          {selectorBoxes}
        </div>
      </div>
      <div className="details">
        <div className="inputs">
          <h2>Informasjon om prosjektet</h2>
          <div className="inputWrapper projectInfo">
            <div className="inputFieldWrapper">
              <label>Prosjektnavn</label>
              <input type="text" value={name} onChange={(e) => setName(e.target.value)} />
            </div>
            <div className="inputFieldWrapper">
              <label>Kunde</label>
              <input value={customer} onChange={e => setCustomer(e.target.value)} type="text" />
            </div>
            <div className="inputFieldWrapper">
              <label>Region</label>
              <select value={region} onChange={e => setRegion(e.target.value)}>
                <option value="0">Velg</option>
                {regionOptions}
              </select>
            </div>
          </div>
          <h2>Informasjon om taket</h2>
          <div className="inputWrapper roofInfo">
            <button className="selected guidebutton" onClick={handleShowGuideModal}>Veiledning</button>
            <div className="inputFieldWrapper">
              <label>Isolert areal</label>
              <input type="number" value={area} onChange={e => setArea(e.target.value)} />
              <div className="unit">m2</div>
            </div>
            <div className="inputFieldWrapper">
              <label>Omkrets</label>
              <input type="number" value={perimeter} onChange={e => setPerimeter(e.target.value)} />
              <div className="unit">lm</div>
            </div>
            { false &&
            <div className="inputFieldWrapper">
              <label>Høyde parapet</label>
              <input type="number" value={pheight} onChange={e => setPHeight(e.target.value)} />
              <div className="unit">mm</div>
            </div>
            }
            { false &&
            <div className="inputFieldWrapper">
              <label>Antall sluker</label>
              <input type="number" value={drains} onChange={e => setDrains(e.target.value)} />
              <div className="unit">stk</div>
            </div>
            }
            { false &&
            <div className="inputFieldWrapper">
              <label>Gjennomføringer</label>
              <input type="number" value={bushings} onChange={e => setBushings(e.target.value)} />
              <div className="unit">stk</div>
            </div>
            }
            <div className="inputFieldWrapper archive">
              <label>Arkivert</label>
              <input type="checkbox" checked={archived} onChange={e => setArchived(e.target.checked)} />
            </div>
            <div className="buttons">
              <button onClick={handleSaveProject} className="selected save">Lagre</button>
              { !!solution &&
              <>
              <button onClick={() => handleSavePDF({ includePrice: true })} className="selected">PDF (m/pris)</button>
              <button onClick={() => handleSavePDF({ includePrice: false })} className="selected">PDF (u/pris)</button>
              </>
              }
            </div>
          </div>
        </div>
        <div className="solutionImage">
          <SolutionImage />
        </div>
      </div>
      <div id="calculation" className="calculation">
        <style>{tablestyle}</style>
        <div className="top">
          <div className="topLeft">
            <h2>{project?.name}</h2>
            <div>{project?.customer}</div>
          </div>
          <div className="estimate">
            <div className="inner">ESTIMAT</div>
          </div>
          <div className="date">
            {project?.updated ? format(new Date(project.updated), DATE_FORMAT) : ''}
          </div>
        </div>
        { !solution &&
          <>
          <div>Fant ingen passende løsning.</div><br/>
          <div>Ta kontakt på telefon <a href={`tel:${CONTACT_PHONE}`}>{CONTACT_PHONE}</a> eller epost <a href={`mailto:${CONTACT_EMAIL}`}>{CONTACT_EMAIL}</a>.</div>
          </>
        }
        { !!solution &&
          <div id="solution" className="solutiontable">
            <div className="spec">
              <div className="head">Spesifikasjon</div>
              <div className="row">
                <h4>Isolering</h4>
                {isolationProducts.map(ip => ip?.name).join(', ')}<br/>
                Motfallskille i lavbrekk<br/>
                U-verdi {solution?.data?.stepValues[3]?.label}<br/>
                Post inkluderer PE-folie 
              </div>
              { isSandwitch &&
                <div className="row">
                  <h4>Brannseksjonering</h4>
                  {firesectionProducts.map(fp => fp?.name).join(', ')}
                </div>
              }
              <div className="row">
                <h4>Tekking</h4>
                Type: {tekkeProducts.map(tp => tp?.name).join(', ')}<br/>
                Innfestning: Mekanisk i henhold til gjeldende vindlast
              </div>
              <div className="row">
                <h4>Avslutning på tekning i form av parapet</h4>
                Type: {parapetProducts.map(tp => tp?.name).join(', ')}<br/>
                Høyde (mm): Inntil {pheight} mm medregnet 
              </div>
              <div className="row">
                <h4>Sluk som en del av tekningssystemet</h4>
                Type: {slukProducts.map(sp => sp?.name).join(', ')} 
              </div>
              <div className="row">
                <h4>Motfallskile av EPS/Mineralull montert i rennedal</h4>
                Type: {kileProducts.map(sp => sp?.name).join(', ')} 
              </div>
              <div className="row">
                <h4>Innteking av gjennomføring i tekking</h4>
                Type: {pipedetailProducts.map(sp => sp?.name).join(', ')}
              </div>
              <div className="row">
                <h4>Rigg</h4>
                Frakt av materialer og utstyr til byggeplass
              </div>
            </div>
            <div className="unit">
              <div className="head">Enhet</div>
              <div className="row">
                <h4>m2</h4>
              </div>
              { isSandwitch &&
                <div className="row">
                  <h4>m2</h4>
                </div>
              }
              <div className="row">
                <h4>m2</h4>
              </div>
              <div className="row">
                <h4>m2</h4>
              </div>
              <div className="row">
                <h4>stk</h4>
              </div>
              <div className="row">
                <h4>stk</h4>
              </div>
              <div className="row">
                <h4>stk</h4>
              </div>
              <div className="row">
                <h4>RS</h4>
              </div>
            </div>
            <div className="amount">
              <div className="head">Mengde</div>
              <div className="row"><h4>{formatNum(isolationProductAmount)}</h4></div>
              { isSandwitch &&
                <div className="row"><h4>{formatNum(firesectionProductAmount)}</h4></div>
              }
              <div className="row"><h4>{formatNum(tekkeProductAmount)}</h4></div>
              <div className="row"><h4>{formatNum(parapetProductAmount)}</h4></div>
              <div className="row"><h4>{formatNum(slukProductAmount)}</h4></div>
              <div className="row"><h4>{formatNum(kileProductAmount)}</h4></div>
              <div className="row"><h4>{formatNum(pipedetailProductAmount)}</h4></div>
              <div className="row"><h4>1</h4></div>
            </div>
            <div className="priceunit">
              <div className="head">Pris/Enhet</div>
              <div className="row"><h4>{formatNum(isolationProductCost)}</h4></div>
              { isSandwitch &&
                <div className="row"><h4>{formatNum(firesectionProductCost)}</h4></div>
              }
              <div className="row"><h4>{formatNum(tekkeProductCost)}</h4></div>
              <div className="row"><h4>{formatNum(parapetProductCost)}</h4></div>
              <div className="row"><h4>{formatNum(slukProductCost)}</h4></div>
              <div className="row"><h4>{formatNum(kileProductCost)}</h4></div>
              <div className="row"><h4>{formatNum(pipedetailProductCost)}</h4></div>
              <div className="row"><h4>-</h4></div>
            </div>
            <div className="sum">
              <div className="head">Sum</div>
              <div className="row"><h4>{formatNum(isolationProductCost * isolationProductAmount)}</h4></div>
              { isSandwitch &&
                <div className="row"><h4>{formatNum(firesectionProductCost * firesectionProductAmount)}</h4></div>
              }
              <div className="row"><h4>{formatNum(tekkeProductCost * tekkeProductAmount)}</h4></div>
              <div className="row"><h4>{formatNum(parapetProductCost * parapetProductAmount)}</h4></div>
              <div className="row"><h4>{formatNum(slukProductCost * slukProductAmount)}</h4></div>
              <div className="row"><h4>{formatNum(kileProductCost * kileProductAmount)}</h4></div>
              <div className="row"><h4>{formatNum(pipedetailProductCost * pipedetailProductAmount)}</h4></div>
              <div className="row"><h4>{formatNum(rigCost)}</h4></div>
            </div>
          </div>
        }
        <div className="finalsum">
          <div className="brand">Protan Prosjektkalkulator B2B</div>
          <div className="spacing"></div>
          <div className="fsum">
            <div className="fsuma">Sum:</div>
            <div className="fsumb">{formatNum(finalCost)}</div>
          </div>
        </div>
        { isSandwitch &&
          <div className="fireInfo">
            <p style={{ color: 'red' }}>Valgt løsning kan ikke benyttes dersom den bærende takkonstruksjonen har uspesifisert brannmotstand.</p>
            <p>
Se TPF- Informerer nr. 6 om "Branntekniske konstruksjoner for tak" (www.tpf-inf.org) for mer
informasjon om bruk av brennbar og ubrennbar isolasjon i kompakte tak.
            </p>
          </div>
        }
      </div>
      <Modal isOpen={showGuideModal} onRequestClose={handleCloseGuideModal} preventScroll={false}>
        <div className="guideModalInner">
          <button className="selected closemodaltop" onClick={handleCloseGuideModal}>Lukk</button>
          <h3>Prosjektkalkulator B2B</h3>
          <p style={{ fontStyle: 'italic'}}>Veiledning</p>
          <p>
Kalkulatoren er beregnet for enkle takkonstruksjoner av selvbærende korrugerte stålplater på lagerbygninger,
haller, næringsbygg eller lignende.o
          </p>
          <p>
solasjons- og tekkeløsningene tar utgangspunkt i TPF Informerer.
          </p>
          <p>
Følgende forutsetninger ligger til grunn i alle beregninger:
          </p>
          <h4>Konstruksjon</h4>
          <ul>
            <li>Selvbærende korrugerte stålplater med minimum tykkelse 0,7mm</li>
            <li>Parapet av sandwichelementer med yttersjikt av tynne stålplater med minimumstykkelse 0,45mm og
nødvendige påmonterte beslag i overganger og skjøter</li>
          </ul>
          <h4>Takets utforming</h4>
          <ul>
            <li>Kvadratisk eller rektangulær form med areal > 2000 m²</li>
            <li>Sluker er plassert med en maksimal innbyrdes avstand på 12 meter i lengde- og bredderetning</li>
            <li>Taket står fritt og har ingen tilstøtende vertikale flater høyere enn sin egen parapet</li>
          </ul>
          <p>
Kalkulatoren inkluderer 2 isolasjonsløsninger; gjennomgående mineralullsløsning og sandwichløsning.
Begge løsningene inkluderer oppbygning som dekker flere ulike U-verdier.
Felles for alle løsninger er at underlaget medtas i beregningene og skal sammen med isolasjon imøtekomme U-verdi
          </p>
          <h4>Beskrivelse av isolasjonsløsningene som inngår i kalkulator:</h4>
          <h4>Mineralullsløsning på horisontale korrugerte stålplater</h4>
          <ul>
            <li>Isolasjonen leveres med fall 1:40</li>
            <li>I lavbrekk benyttes dobbel motfallskile mellom sluker</li>
            <li>I lavbrekk ved parapet benyttes enkel motfallskile.</li>
            <li>Isolasjonsplatene legges i flere lag med forskjøvne skjøter</li>
            <li>0,2mm PE folie legges mellom første og andre isolasjonssjikt</li>
            <li>Isolasjonsplatene festes mekanisk sammen med takbelegget</li>
          </ul>
          <h4>Mineralullsløsning på korrugerte stålplater med fall</h4>
          <ul>
            <li>Isolasjonen som benyttes er jevntykk</li>
            <li>I lavbrekk benyttes dobbel motfallskile mellom sluker</li>
            <li>I lavbrekk ved parapet benyttes enkel motfallskile.</li>
            <li>Isolasjonsplatene legges i flere lag med forskjøvne skjøter</li>
            <li>0,2mm PE folie legges mellom første og andre isolasjonssjikt</li>
            <li>Isolasjonsplatene festes mekanisk sammen med takbelegget.</li>
          </ul>
          <h4>Sandwichløsning på horisontale korrugerte stålplater</h4>
          <ul>
            <li>Mineralullsisolasjon legges i bunn og topp</li>
            <li>EPS-isolasjon legges i midtre sjikt med fall 1:40</li>
            <li>Brannseksjonering med mineralull i bredde 600mm langs parapet</li>
            <li>I lavbrekk benyttes dobbel motfallskile mellom sluker</li>
            <li>I lavbrekk ved parapet benyttes enkel motfallskile.</li>
            <li>Isolasjonsplatene legges i flere lag med forskjøvne skjøter</li>
            <li>0,2mm PE folie legges mellom første og andre isolasjonssjikt</li>
            <li>Isolasjonsplatene festes mekanisk sammen med takbelegget.</li>
          </ul>
          <h4>Sandwichløsning på korrugerte stålplater med fall</h4>
          <ul>
            <li>Mineralullsisolasjon legges i bunn og topp</li>
            <li>Jevntykk EPS-isolasjon legges i midtre sjikt</li>
            <li>Brannseksjonering med mineralull i bredde 600mm langs parapet</li>
            <li>I lavbrekk benyttes dobbel motfallskile mellom sluker</li>
            <li>I lavbrekk ved parapet benyttes enkel motfallskile.</li>
            <li>Isolasjonsplatene legges i flere lag med forskjøvne skjøter</li>
            <li>0,2mm PE folie legges mellom første og andre isolasjonssjikt</li>
            <li>Isolasjonsplatene festes mekanisk sammen med takbelegget.</li>
          </ul>
          <h4>Tekkeløsning som inngår i kalkulator:</h4>
          <ul>
            <li>Takbelegg av Protan SE 1,6mm polyesterarmert PVC-folie.</li>
            <li>Mekanisk innfestet med inntil 4 festemidler per kvadratmeter</li>
            <li>Parapetskjørt av Protan SE 1,6mm folie montert med mekanisk innfestet stålskinne i hulkil</li>
            <li>Jual innstikksluk med tilhørende prefabrikkert flens av PVC</li>
          </ul>
        </div>
      </Modal>
    </div>
  )
}
