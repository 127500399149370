import { useMemo, useState } from 'react'
import { useTable } from 'react-table'
import { format } from 'date-fns'
import { toast } from 'react-toastify'
import Modal from 'react-modal'
import { useStore } from '../../shared/state'
import { DATE_FORMAT } from '../../shared/config'
import { 
  createCompany,
  updateCompany,
  deleteCompany 
} from '../../shared/api'
import { Input } from '../../shared/components/Input'
import './index.css'

Modal.setAppElement('#root')

export const Companies = (props) => {
  const {companies, setCompanies} = useStore('state') 
  const [showModal, setShowModal] = useState(false)
  const [id, setId] = useState(0)
  const [name, setName] = useState('')
  const [adding, setAdding] = useState(false)
  const [discount, setDiscount] = useState('')
  const [archived, setArchived] = useState(false)
  const [showArchived, setShowArchived] = useState(false)

  const columns = useMemo(
   () => [
     {
       Header: 'Navn',
       accessor: 'name', // accessor is the "key" in the data
     },
     {
       Header: 'Påslag',
       accessor: (row, index) => {
        return `${row.discount} %` 
      } 
     },
     {
       Header: 'Opprettet',
       accessor: (row, index) => {
        return format(new Date(row.created), DATE_FORMAT)
      } 
     },
     {
       Header: 'Oppdatert',
       accessor: (row, index) => {
        return format(new Date(row.updated), DATE_FORMAT)
      } 
     },
   ],
   []
  )

  const data = companies.filter(u => u.archived !== !showArchived) 
  const tableInstance = useTable({ columns, data })
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = tableInstance

  const handleAddCompany = async () => {
    setShowModal(true)
    setId(0)
    setName('')
    setAdding(true)
    setDiscount('')
  }

  const closeModal = () => {
    setShowModal(false)
    setId(0)
    setName('')
    setAdding(false)
    setDiscount('')
  }

  const handleEditCompany = (company) => {
    setShowModal(true)
    setId(company.id)
    setName(company.name)
    setAdding(false)
    setDiscount(company.discount)
    setArchived(company.archived)
  }

  const validateInput = () => {
    let valid = true
    if (!name || name === '') {
      valid=false
      toast.error('Navn påkrevd')
    }
    if (!discount || discount < 0 || discount > 100) {
      valid=false
      toast.error('Påslag må være mellom 0 og 100')
    }
    return valid 
  }

  const handleCreateCompany = async () => {
    if (!validateInput()) return
    const update = async () => {
      const company = await createCompany({ name, discount, archived })
      setCompanies([company].concat(companies))
      closeModal()
    }
    toast.promise(
      update(), 
      {
        pending: 'Oppretter selskap',
        success: 'Selskap opprettet',
        error: 'Noe gikk galt'
      }
    )
  }

  const handleUpdateCompany = async () => {
    if (!validateInput()) return
    const update = async () => {
      const company = await updateCompany({ id, name, discount, archived })
      setCompanies(companies.map(c => {
        if (c.id === id) return company
        return c
      }))
    }
    toast.promise(
      update(), 
      {
        pending: 'Oppdaterer selskap',
        success: 'Selskap oppdatert', 
        error: 'Noe gikk galt'
      }
    )
    closeModal()
  }

  const handleDeleteCompany = async () => {
    const confirmed = window.confirm('Sikker?')
    if (!confirmed) return
    const update = async () => {
      await deleteCompany({ id })
      setCompanies(companies.filter(c => c.id !== id))
    }
    toast.promise(
      update(), 
      {
        pending: 'Sletter selskap',
        success: 'Selskap slettet', 
        error: 'Noe gikk galt'
      }
    )
    closeModal()
  }

  const handleToggleArchived = async () => {
    setShowArchived(!showArchived) 
  }

  return (
    <div className="MainBody Companies">
      <Modal 
        isOpen={showModal}
        onRequestClose={closeModal}
      >
        <div className="companiesModalInner">
          <h1>Legg til selskap</h1>
          <div className="form">
            <Input type="text" placeholder="Navn på selskap" value={name} onChange={(e) => setName(e.target.value)} />
            <Input type="number" placeholder="Påslag (%)" min="0" max="100" value={discount} onChange={(e) => setDiscount(e.target.value)} />
            <div className="checkbox">
              <input type="checkbox" placeholder="Arkivert" checked={archived} onChange={(e) => setArchived(e.target.checked)} />
              <label>Arkivert</label>
            </div>
          </div>
          <div className="buttons">
            <button onClick={closeModal}>Avbryt</button>
            <div>
            { !adding &&
            <button className="delete" onClick={handleDeleteCompany}>Slett</button>
            }
            <button onClick={adding ? handleCreateCompany : handleUpdateCompany}>Lagre</button>
            </div>
          </div>
        </div>
      </Modal>
      <div className="top">
        <h1>Selskap</h1>
        <div className="buttons">
          <button className={showArchived ? 'selected' : ''} onClick={handleToggleArchived}>Arkiv</button>
          <button onClick={handleAddCompany}>Legg til</button>
        </div>
      </div>
      <div className="companyTable">
       <table {...getTableProps()}>
         <thead>
           {// Loop over the header rows
           headerGroups.map(headerGroup => (
             // Apply the header row props
             <tr {...headerGroup.getHeaderGroupProps()}>
               {// Loop over the headers in each row
               headerGroup.headers.map(column => (
                 // Apply the header cell props
                 <th {...column.getHeaderProps()}>
                   {// Render the header
                   column.render('Header')}
                 </th>
               ))}
             </tr>
           ))}
         </thead>
         {/* Apply the table body props */}
         <tbody {...getTableBodyProps()}>
           {// Loop over the table rows
           rows.map(row => {
             // Prepare the row for display
             prepareRow(row)
             return (
               // Apply the row props
               <tr {...row.getRowProps()} onClick={() => handleEditCompany(row.original)}>
                 {// Loop over the rows cells
                 row.cells.map(cell => {
                   // Apply the cell props
                   return (
                     <td {...cell.getCellProps()}>
                       {// Render the cell contents
                       cell.render('Cell')}
                     </td>
                   )
                 })}
               </tr>
             )
           })}
         </tbody>
       </table>
      </div>
    </div>
  )
}
