export const exportstyle = ({ includePrice }) => `
#pdfclone .solutiontable {
  font-size: 0.75em;
}
#pdfclone .solutiontable .row {
  height: 120px;
}
${ !includePrice ? `

#pdfclone .finalsum {
  display: none;
}

#pdfclone .priceunit {
  display: none;
}

#pdfclone .sum {
  display: none;
}

` : '' }
`

export const tablestyle = `
.calculation {
  flex: 1;
  padding: 0px 20px;
  padding-bottom: 20px;
  background-color: white;
  border: 1px solid var(--color-blue-dark);
  margin-top: 20px;
}

.calculation .top {
  padding-left: 10px;
  margin-bottom: 50px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.calculation .top .estimate {
  display: flex;
  justify-content: center;
  align-items: center; 
}
.calculation .top .estimate .inner {
  font-size: 1.6em;
  color: red;
  padding: 8px;
  border: 2px dotted red;
  border-radius: 10px;
  opacity: 0.5;
}
.calculation .top .date {
  display: flex;
  justify-content: center;
  align-items: center; 
  font-weight: bold;
}

.solutiontable {
  display: flex;
}

.solutiontable > div {
  border-right: 1px solid var(--color-blue-dark);
  padding: 10px;
}
.solutiontable .spec {
  flex: 1;
}
.solutiontable .unit, 
.solutiontable .amount,
.solutiontable .priceunit,
.solutiontable .sum {
  padding-left: 20px;
  padding-right: 20px;
}
.solutiontable .unit, 
.solutiontable .amount,
.solutiontable .priceunit {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.solutiontable .head {
  font-weight: bold;
  padding-bottom: 10px;
  text-align: right;
}
.solutiontable .spec .head {
  text-align: left;
}

.solutiontable .row {
  padding: 5px 0px;
  height: 125px;
  overflow: hidden;
  font-size: 0.8em;
  font-style: italic;
  line-height: 1.4em;
  border-bottom: 1px dotted var(--color-blue-dark);
  text-align: right;
}
.solutiontable .row:last-of-type {
  border-bottom: none; 
}
.solutiontable .spec .row {
  text-align: left;
}
.solutiontable .unit .row h4 {
  font-weight: normal;
}
.solutiontable .amount .row h4 {
  font-weight: normal;
}
.solutiontable .priceunit .row h4 {
  font-weight: normal;
}
.solutiontable .sum .row h4 {
  font-weight: normal;
}
.solutiontable .row h4 {
  font-style: normal;
}

.solutiontable .sum {
  border-right: none;
}

.finalsum {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}
.finalsum .brand {
  font-size: 0.8em;
  display: flex;
  justify-content: center;
  align-items: center;
}
.finalsum .fsum {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
}
.finalsum .fsumb {
  margin-left: 10px;
}

.fireInfo {
  font-size: 0.8em;
}
`
