export const DATE_FORMAT = 'dd-MM-yyyy'
export const CONTACT_PHONE = '32 22 16 00'
export const CONTACT_EMAIL = 'gard.brunsell@protan.no'
export const SELECTOR_CHOICES = [
  {
    id: 'A',
    label: 'Bærende konstruksjon',
    options: [
      { label: 'TRP Stålplater m/fall',  value: 'A1' },
      { label: 'TRP Stålplater u/fall',  value: 'A2' },
//      { label: 'Betong',                 value: 'A3' },
    ]
  },
  {
    id: 'B',
    label: 'Parapet underlag',
    options: [
      { label: 'Sandwichelementer',   value: 'B1' },
//      { label: 'Betong',              value: 'B2' },
//      { label: 'Tre',                 value: 'B3' },
    ]
  },
  {
    id: 'C',
    label: 'Isolasjonsløsning',
    options: [
      { label: 'Mineralull',                    value: 'C1' },
//      { label: 'Sandwich',                      value: 'C2',  filter: ['A1,B1', 'A1,B3', 'A3,B3'] },
      { label: 'Sandwich m/brannseksjonering',  value: 'C3' },
    ]
  },
  {
    id: 'D',
    label: 'U-verdi',
    options: [
      { label: '0,10', value: 'D1' },
      { label: '0,13', value: 'D2' },
      { label: '0,15', value: 'D3', filter: ['A2,B1,C1'] },
    ]
  },
  {
    id: 'E',
    label: 'Tekkeløsning',
    options: [
      { label: 'Protan SE 1,2mm mek. festet', value: 'E1' },
      { label: 'Protan SE 1,6mm mek. festet', value: 'E2' },
    ]
  }
]
export const PAASLAG = {
  protan: {
    id: 'protan',
    name: 'Protan',
    label: 'Fraktpåslag Protan (NOK/m3)'
  },
  glava: {
    id: 'glava',
    name: 'Rockwool',
    label: 'Fraktpåslag Rockwool (NOK/m3)'
  },
  sunde: {
    id: 'sunde',
    name: 'Brødrene Sunde',
    label: 'Fraktpåslag Brødrene Sunde (NOK/m3)'
  }
}

