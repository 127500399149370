import { createContext, useContext } from 'react'

const contexts = {}

export function createStore(name, def) {
  const ctx = createContext(def)
  contexts[name] = ctx
  return ctx 
}

export function useStore(name) {
  return useContext(contexts[name]) 
}
