import { useState, useEffect } from 'react'
import { nav } from 'tiny-react-router'
import { toast } from 'react-toastify'
import { 
  createSolution,
  updateSolution,
  deleteSolution 
} from '../../shared/api'
import { useStore } from '../../shared/state'
import { SELECTOR_CHOICES } from '../../shared/config'
import './index.css'

export const SolutionEditor = (props) => {
  const { products, solutions, setSolutions } = useStore('state')
  // eslint-disable-next-line 
  let solution = solutions.filter(p => p.id == props.id)[0]
  if (!solution) solution = {
    id: 0,
    name: 'Ny løsning',
    data: {
      area: 0,
      step: 0,
      drains: 0,
      pheight: 0,
      bushings: 0,
      perimeter: 0,
      stepValues: []
    },
    products: []
  }

  const [name, setName] = useState(solution.name)
  const [step, setStep] = useState(solution.data.step)
  const [area, setArea] = useState(solution.data.area)
  const [drains, setDrains] = useState(solution.data.drains)
  const [pheight, setPHeight] = useState(solution.data.pheight)
  const [bushings, setBushings] = useState(solution.data.bushings)
  const [perimeter, setPerimeter] = useState(solution.data.perimeter)
  const [stepValues, setStepValues] = useState(solution.data.stepValues)
  const [solutionProducts, setSolutionProducts] = useState(solution.products)
  const [showAllOptionsForSteps, setShowAllOptionForSteps] = useState([])

  useEffect(() => {
    setName(solution.name)
    setStep(solution.data.step)
    setArea(solution.data.area)
    setDrains(solution.data.drains)
    setPHeight(solution.data.pheight)
    setBushings(solution.data.bushings)
    setPerimeter(solution.data.perimeter)
    setStepValues(solution.data.stepValues)
    setSolutionProducts(solution.products)
  // eslint-disable-next-line
  }, [solutions, props.id])

  const handleStepHeaderClick = (stepIndex) => {
    if (stepIndex >= step) return
    let _showAllOptionsForSteps = [].concat(showAllOptionsForSteps)
    if (_showAllOptionsForSteps.indexOf(stepIndex) >= 0)
      _showAllOptionsForSteps = _showAllOptionsForSteps.filter(s => s !== stepIndex)
    else
      _showAllOptionsForSteps.push(stepIndex)
    setShowAllOptionForSteps(_showAllOptionsForSteps)
  }

  const handleStepSelect = (stepIndex, option) => {
    let _stepValues = stepValues
    _stepValues[stepIndex] = option
    _stepValues = _stepValues.filter((s,i) => i <= stepIndex)
    setStepValues(_stepValues)
    if (stepIndex+1 < SELECTOR_CHOICES.length)
      setStep(stepIndex+1)
  }

  const validateSolutionData = (solution) => {
    let valid = true
    if (name === '') { valid = false; toast('Løsningen må ha et navn', { type: 'warning' }) }
    if (solution.data.stepValues.length < 5) {valid = false; toast('Alle steg må ha en verdi', { type: 'warning' }) }
    return valid 
  }

  const handleAddProductToSolution = async (p) => {
    setSolutionProducts(solutionProducts.concat(p))
  }

  const handleRemoveProductToSolution = async (p) => {
    setSolutionProducts(solutionProducts.filter(sp => sp.id !== p.id))
  }

  const handleSaveSolution = async () => {
    const updates = {
      name: name,
      data: {
        step: step,
        area: area,
        drains: drains,
        pheight: pheight,
        bushings: bushings,
        perimeter: perimeter,
        stepValues: stepValues
      },
      products: solutionProducts
    }
    if (!validateSolutionData(updates)) return
    const updated = Object.assign({}, solution, updates)
    if (updated.id === 0) {
      delete updated.id
      toast.promise(
        createSolution(updated),
        {
          pending: 'Oppretter løsning',
          success: {
            render({ data }) { 
              setSolutions([].concat(solutions, [data]))
              nav(`/solutions/${data.id}`)
              return 'Løsning opprettet' 
            }
          },
          error: 'Noe gikk galt'
        }
      )
    } else {
      toast.promise(
        updateSolution(updated),
        {
          pending: 'Oppdaterer løsning',
          success: {
            render({ data }) { 
              setSolutions(solutions.map(p => p.id === data.id ? data : p))
              return 'Løsning lagret' 
            }
          },
          error: 'Noe gikk galt'
        }
      )
    }
  }

  const handleDeleteSolution = async () => {
    const agree = window.confirm('Er du sikker?')
    if (!agree) return
    toast.promise(
      deleteSolution(solution),
      {
        pending: 'Sletter løsning',
        success: {
          render({ data }) { 
            setSolutions(solutions.filter(s => s.id !== solution.id)) 
            nav('/solutions')
            return 'Løsning slettet' 
          },
          type: 'error'
        },
        error: 'Noe gikk galt'
      }
    )
  }

  const selectorBoxes = SELECTOR_CHOICES.map((c,i) => {
    const hasValue = stepValues[i] !== undefined
    const openMenu = showAllOptionsForSteps.indexOf(i) >= 0
    const selectPath = stepValues.filter((v,_i) => _i < i).map(v => v.value).join(',')
    const options = c.options
      .filter(o => {
        if (o.filter && o.filter.indexOf(selectPath) >= 0) return false
        if (openMenu) return true
        if (hasValue && stepValues[i]?.value !== o.value) return false
        return true
      })
      .map(o => {
        const selected = stepValues[i] && stepValues[i].value === o.value
        return (
          <div key={o.value} className={`option ${selected ? 'selected' : ''}`} onClick={() => handleStepSelect(i, o)}>{`- ${o.label}`}</div>
        )
      })
    return (
      <div key={c.id}>
        <div className={`selectorBox ${(!hasValue && step === i) || openMenu ? 'open' : 'closed'}`}>
          <div className="name" onClick={() => handleStepHeaderClick(i)}>{c.label}</div>
          { SELECTOR_CHOICES[step].id >= c.id &&
            <>
            {options}
            </>
          }
        </div>
      </div>
    )
  })

  const selectedProductIds = solutionProducts.map(p => p.id)
  const availableProducts = products.filter(p => selectedProductIds.indexOf(p.id) < 0).map(p => {
    return (
      <div className="product selectable" key={p.id} onClick={() => handleAddProductToSolution(p)}>
        <div className="name">{p.name}</div>
        <div className="category">{p.category}</div>
        <div className="spacer" style={{ flex: 'auto' }}></div>
        <div className="cost">{p.cost},-</div>
      </div>
    )
  })
  const selectedProducts = solutionProducts.map(p => {
    return (
      <div className="product selectable" key={p.id} onClick={() => handleRemoveProductToSolution(p)}>
        <div className="name">{p.name}</div>
        <div className="category">{p.category}</div>
        <div className="spacer" style={{ flex: 'auto' }}></div>
        <div className="cost">{p.cost},-</div>
      </div>
    )
  })

  return (
    <div className="MainBody SolutionEditor">
      <div className="label">
        <h2>{name}</h2>
      </div>
      <div className="selector">
        <div className="selectorLine">
          <div><div></div><div></div></div>
          <div><div></div><div></div></div>
          <div><div></div><div></div></div>
          <div><div></div><div></div></div>
          <div><div></div><div></div></div>
        </div>
        <div className="selectorBoxes">
          {selectorBoxes}
        </div>
      </div>
      <div className="details">
        <div className="inputs">
          <div className="inputWrapper solutionInfo">
            <div className="inputFieldWrapper">
              <label>Navn på løsning</label>
              <input type="text" value={name} onChange={(e) => setName(e.target.value)} />
            </div>
          </div>
        </div>
      </div>
      <div className="products">
        <div className="availableProducts productBoxWrapper">
          <h2>Tilgjengelige produkter</h2>
          <div className="productBox">
            {availableProducts}
          </div>
        </div>
        <div className="selectedProducts productBoxWrapper">
          <h2>Valgte produkter</h2>
          <div className="productBox">
            {selectedProducts}
          </div>
        </div>
      </div>
      <div className="buttons">
        <button onClick={handleSaveSolution} className="selected">Lagre</button>
        <button onClick={handleDeleteSolution} className="delete">Slett</button>
      </div>
    </div>
  )
}
