import { useMemo, useState } from 'react'
import { useTable } from 'react-table'
import { format } from 'date-fns'
import { nav } from 'tiny-react-router'
import { DATE_FORMAT } from '../../shared/config'
import { useStore } from '../../shared/state'
import pencilAndRuler from '../../graphics/pencil-and-ruler.png'
import './index.css'

export const MyProjects = (props) => {
  const {user, projects} = useStore('state')
  const [archived, setArchived] = useState(false)

  // eslint-disable-next-line
  const myProjects = projects.filter(p => p.creator == user?.id)
  const columns = useMemo(() => [
    {
      Header: 'Navn',
      accessor: 'name',
    },
    {
      Header: 'Kunde',
      accessor: 'customer',
    },
    {
      Header: 'Opprettet',
      accessor: (row, index) => {
        return format(new Date(row.created), DATE_FORMAT) 
      }
    },
    {
      Header: 'Oppdater',
      accessor: (row, index) => {
        return format(new Date(row.updated), DATE_FORMAT) 
      }
    },
  ], [])
  const data = myProjects.filter(p => p.archived === archived)
  const tableInstance = useTable({ columns, data })
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = tableInstance
  if (!user) return null

  return (
    <div className="MainBody MyProjects">
      <div className="userinfo">
        <div>{user?.name}</div>
        <div>{user?.companyname}</div>
        <div>{user?.email}</div>
      </div>
      <div className="label">
        <h2>Opprett nytt prosjekt</h2>
        <img src={pencilAndRuler} alt="PencilAndRuler" onClick={() => nav('/projects/new')} />
      </div>
      <div className="myprojects">
       <h3>Mine prosjekt</h3>
       <div className="buttons">
        <button className={archived ? 'selected' : ''} onClick={() => setArchived(!archived)}>Arkiv</button>
       </div>
       <table {...getTableProps()}>
         <thead>
           {// Loop over the header rows
           headerGroups.map(headerGroup => (
             // Apply the header row props
             <tr {...headerGroup.getHeaderGroupProps()}>
               {// Loop over the headers in each row
               headerGroup.headers.map(column => (
                 // Apply the header cell props
                 <th {...column.getHeaderProps()}>
                   {// Render the header
                   column.render('Header')}
                 </th>
               ))}
             </tr>
           ))}
         </thead>
         {/* Apply the table body props */}
         <tbody {...getTableBodyProps()}>
           {// Loop over the table rows
           rows.map(row => {
             // Prepare the row for display
             prepareRow(row)
             return (
               // Apply the row props
               <tr {...row.getRowProps()} onClick={() => nav(`/projects/${row.original.id}`)}>
                 {// Loop over the rows cells
                 row.cells.map(cell => {
                   // Apply the cell props
                   return (
                     <td {...cell.getCellProps()}>
                       {// Render the cell contents
                       cell.render('Cell')}
                     </td>
                   )
                 })}
               </tr>
             )
           })}
         </tbody>
       </table>
      </div>
    </div>
  )
}
