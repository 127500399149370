import { useMemo, useState } from 'react'
import { useTable } from 'react-table'
import { toast } from 'react-toastify'
import emailValidator from 'email-validator'
import Modal from 'react-modal'
import { 
  createUser,
  updateUser,
  deleteUser,
} from '../../shared/api'
import { useStore } from '../../shared/state'
import { Input } from '../../shared/components/Input'
import './index.css'

Modal.setAppElement('#root')

export const Users = (props) => {
  const {users, companies, setUsers} = useStore('state') 
  const [showModal, setShowModal] = useState(false)
  const [id, setId] = useState(0)
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [company, setCompany] = useState(0)
  const [password, setPassword] = useState('')
  const [administrator, setAdmin] = useState(false)
  const [adding, setAdding] = useState(false)
  const [archived, setArchived] = useState(false)
  const [showArchived, setShowArchived] = useState(false)
  const [searchFilter, setSearchFilter] = useState('')

  const columns = useMemo(
   () => [
     {
       Header: 'Navn',
       accessor: 'name', // accessor is the "key" in the data
     },
     {
       Header: 'Epost',
       accessor: 'email', 
     },
     {
       Header: 'Telefon',
       accessor: 'phone', 
     },
     {
       Header: 'Selskap',
       accessor: 'companyname'
     },
     {
       Header: 'Admininstrator',
       accessor: (row, index) => {
        return row.administrator ? 'Ja' : 'Nei' 
      }
     },
   ],
   []
  )

  const data = users
    .filter(u => u?.archived !== !showArchived)
    .filter(u => {
      if (searchFilter === '') return true
      if (u.name === undefined || u.email === undefined || u.companyname === undefined) return false
      let match = 
        u.name.toLowerCase().indexOf(searchFilter.toLowerCase()) >= 0 ||
        u.email.toLowerCase().indexOf(searchFilter.toLowerCase()) >= 0 ||
        u.companyname.toLowerCase().indexOf(searchFilter.toLowerCase()) >= 0
      return match
    })
  const tableInstance = useTable({ columns, data })
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = tableInstance

  const setNullUser = () => {
    setId(0)
    setName('')
    setEmail('')
    setPhone('')
    setAdding(true)
    setCompany(0)
    setPassword('')
    setArchived(false)
  }

  const setEditUser = (User) => {
    setId(User.id)
    setName(User.name)
    setEmail(User.email)
    setPhone(User.phone || '')
    setAdmin(User.administrator)
    setAdding(false)
    setCompany(User.company)
    setPassword(User.password || '')
    setArchived(User.archived)
  }

  const handleAddUser = async () => {
    setShowModal(true)
    setNullUser()
  }

  const closeModal = () => {
    setShowModal(false)
    setNullUser()
  }

  const handleEditUser = (User) => {
    setShowModal(true)
    setEditUser(User)
  }

  const validateUser = () => {
    let valid = true
    if (!name    || name === '') {
      valid = false
      toast.error('Navn påkrevd')
    }
    if (!email   || email === '' || !emailValidator.validate(email)) {
      valid = false
      toast.error('Epost påkrevd')
    }
    if (!company || company === '0') {
      valid = false
      toast.error('Selskap påkrevd')
    }
    return valid
  }

  const handleCreateUser = async () => {
    if (!validateUser()) return
    const update = async () => {
      const user = await createUser({ name, email, phone, password, company, administrator, archived })
      setUsers([user].concat(users))
    }
    toast.promise(
      update(), 
      {
        pending: 'Oppretter bruker',
        success: 'Bruker opprettet',
        error: 'Noe gikk galt'
      }
    )
    closeModal()
  }

  const handleUpdateUser = async () => {
    if (!validateUser()) return
    const update = async () => {
      const user = await updateUser({ id, name, email, phone, password, company, administrator, archived })
      setUsers(users.map(c => {
        if (c.id === id) return user
        return c
      }))
    }
    toast.promise(
      update(), 
      {
        pending: 'Oppdaterer bruker',
        success: 'Bruker oppdatert',
        error: 'Noe gikk galt'
      }
    )
    closeModal()
  }

  const handleDeleteUser = async () => {
    const confirmed = window.confirm('Sikker?')
    if (!confirmed) return
    const update = async () => {
      await deleteUser({ id })
      setUsers(users.filter(c => c.id !== id))
    }
    toast.promise(
      update(), 
      {
        pending: 'Sletter bruker',
        success: 'Bruker slettet',
        error: 'Noe gikk galt'
      }
    )
    closeModal()
  }

  const handleToggleArchived = async () => {
    setShowArchived(!showArchived) 
  }

  const companyOptions = companies.filter(c => !c.archived).map(c => {
    return (
      <option key={c.id} value={c.id}>{c.name}</option>
    )
  })

  return (
    <div className="MainBody Users">
      <Modal 
        isOpen={showModal}
        onRequestClose={closeModal}
      >
        <div className="usersModalInner">
          <h1>Legg til bruker</h1>
          <div className="form">
            <Input id="name" type="text"  autoComplete="name" placeholder="Navn" value={name} onChange={(e) => setName(e.target.value)} />
            <Input id="email" type="email" autoComplete="email" placeholder="Epost" value={email} onChange={(e) => setEmail(e.target.value)} />
            <Input id="phone" type="text" autoComplete="tel-national" placeholder="Telefon" value={phone} onChange={(e) => setPhone(e.target.value)} />
            <Input id="password" type="password" autoComplete="no" placeholder="Passord" value={password} onChange={(e) => setPassword(e.target.value)} />
            <select value={company} onChange={(e) => setCompany(e.target.value)}>
              <option value="0">-</option>
              {companyOptions}
            </select>
            <div className="checkbox">
              <input type="checkbox" placeholder="Administrator" checked={administrator} onChange={(e) => setAdmin(e.target.checked)} />
              <label>Administrator</label>
            </div>
            <div className="checkbox">
              <input type="checkbox" placeholder="Arkivert" checked={archived} onChange={(e) => setArchived(e.target.checked)} />
              <label>Arkivert</label>
            </div>
          </div>
          <div className="buttons">
            <button onClick={closeModal}>Avbryt</button>
            <div>
            { !adding &&
            <button className="delete" onClick={handleDeleteUser}>Slett</button>
            }
            <button onClick={adding ? handleCreateUser : handleUpdateUser}>Lagre</button>
            </div>
          </div>
        </div>
      </Modal>
      <div className="top">
        <h1>Brukere</h1>
        <div className="search">
          <input type="text" value={searchFilter} placeholder="Søk" onChange={e => setSearchFilter(e.target.value)} />
        </div>
        <div className="buttons">
          <button className={showArchived ? 'selected' : ''} onClick={handleToggleArchived}>Arkiv</button>
          <button onClick={handleAddUser}>Legg til</button>
        </div>
      </div>
      <div className="UserTable">
       <table {...getTableProps()}>
         <thead>
           {// Loop over the header rows
           headerGroups.map(headerGroup => (
             // Apply the header row props
             <tr {...headerGroup.getHeaderGroupProps()}>
               {// Loop over the headers in each row
               headerGroup.headers.map(column => (
                 // Apply the header cell props
                 <th {...column.getHeaderProps()}>
                   {// Render the header
                   column.render('Header')}
                 </th>
               ))}
             </tr>
           ))}
         </thead>
         {/* Apply the table body props */}
         <tbody {...getTableBodyProps()}>
           {// Loop over the table rows
           rows.map(row => {
             // Prepare the row for display
             prepareRow(row)
             return (
               // Apply the row props
               <tr {...row.getRowProps()} onClick={() => handleEditUser(row.original)}>
                 {// Loop over the rows cells
                 row.cells.map(cell => {
                   // Apply the cell props
                   return (
                     <td {...cell.getCellProps()}>
                       {// Render the cell contents
                       cell.render('Cell')}
                     </td>
                   )
                 })}
               </tr>
             )
           })}
         </tbody>
       </table>
      </div>
    </div>
  )
}
