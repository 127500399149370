import { useMemo, useState } from 'react'
import { useTable } from 'react-table'
import { format } from 'date-fns'
import { toast } from 'react-toastify'
import Modal from 'react-modal'
import { useStore } from '../../shared/state'
import { 
  PAASLAG, 
  DATE_FORMAT, 
} from '../../shared/config'
import { 
  createProduct,
  updateProduct,
  deleteProduct 
} from '../../shared/api'
import { Input } from '../../shared/components/Input'
import './index.css'

Modal.setAppElement('#root')

export const Products = (props) => {
  const { products, setProducts } = useStore('state') 
  const { config } = useStore('state')
  const [id, setId] = useState(0)
  const [name, setName] = useState('')
  const [cost, setCost] = useState('')
  const [adding, setAdding] = useState(false)
  const [workcost, setWorkCost] = useState('')
  const [category, setCategory] = useState('')
  const [supplier, setSupplier] = useState('')
  const [archived, setArchived] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [showArchived, setShowArchived] = useState(false)
  const [transportcost, setTransportCost] = useState('')

  const columns = useMemo(
   () => [
     {
       Header: 'Navn',
       accessor: 'name', // accessor is the "key" in the data
     },
     {
       Header: 'Kategori',
       accessor: (row, index) => {
        return `${row.category}` 
      } 
     },
     {
       Header: 'Produktkost/enhet',
       accessor: (row, index) => {
        return `${row.cost}` 
      } 
     },
     {
       Header: 'Arbeidstid/enhet',
       accessor: (row, index) => {
        return `${row.work_cost}` 
      } 
     },
     {
       Header: 'Tykkelse(mm)',
       accessor: (row, index) => {
        return `${row.transport_cost}` 
      } 
     },
     {
       Header: 'Leverandør',
       accessor: (row, index) => {
        return `${PAASLAG[row.supplier]?.name || ''}` 
      } 
     },
//     {
//       Header: 'Opprettet',
//       accessor: (row, index) => {
//        return format(new Date(row.created), DATE_FORMAT)
//      } 
//     },
     {
       Header: 'Oppdatert',
       accessor: (row, index) => {
        return format(new Date(row.updated), DATE_FORMAT)
      } 
     },
   ],
   []
  )

  const data = products.filter(u => u.archived !== !showArchived) 
  const tableInstance = useTable({ columns, data })
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = tableInstance

  const handleAddProduct = async () => {
    setShowModal(true)
    setId(0)
    setName('')
    setAdding(true)
    setCategory('')
    setCost('')
    setWorkCost('')
    setTransportCost('')
    setSupplier('')
  }

  const closeModal = () => {
    setShowModal(false)
    setId(0)
    setName('')
    setAdding(false)
    setCategory('')
    setCost('')
    setWorkCost('')
    setTransportCost('')
    setSupplier('')
  }

  const handleEditProduct = (product) => {
    setShowModal(true)
    setId(product.id)
    setName(product.name)
    setAdding(false)
    setCategory(product.category)
    setArchived(product.archived)
    setCost(product.cost)
    setWorkCost(product.work_cost)
    setTransportCost(product.transport_cost)
    setSupplier(product.supplier || '')
  }

  const validateProduct = () => {
    let valid = true
    if (!name || name === '') {
      valid=false
      toast.error('Navn påkrevd')
    } 
    if (!category) {
      valid=false
      toast.error('Kategori påkrevd')
    }
    if (!supplier || supplier === '') {
      valid=false
      toast.error('Leverandør påkrevd')
    }
    if (isNaN(parseFloat(cost))) {
      valid=false
      toast.error('Produtkost må være et tall')
    }
    if (isNaN(parseFloat(workcost))) {
      valid=false
      toast.error('Arbeidskost må være et tall')
    }
    if (isNaN(parseFloat(transportcost))) {
      valid=false
      toast.error('Tykkelse må være et tall')
    }
    return valid
  }

  const handleCreateProduct = async () => {
    if (!validateProduct()) return
    const update = async () => {
      const product = await createProduct({ name, cost, work_cost: workcost, transport_cost: transportcost, supplier, category, archived })
      setProducts([product].concat(products))
    }
    toast.promise(
      update(), 
      {
        pending: 'Oppretter produkt',
        success: 'Produkt opprettet',
        error: 'Noe gikk galt'
      }
    )
    closeModal()
  }

  const handleUpdateProduct = async () => {
    if (!validateProduct()) return
    const update = async () => {
      const product = await updateProduct({ id, name, cost, work_cost: workcost, transport_cost: transportcost, supplier, category, archived })
      setProducts(products.map(c => {
        if (c.id === id) return product 
        return c
      }))
    }
    toast.promise(
      update(), 
      {
        pending: 'Oppdaterer produkt',
        success: 'Produkt oppdatert',
        error: 'Noe gikk galt'
      }
    )
    closeModal()
  }

  const handleDeleteProduct = async () => {
    const confirmed = window.confirm('Sikker?')
    if (!confirmed) return
    const update = async () => {
      await deleteProduct({ id })
      setProducts(products.filter(c => c.id !== id))
    }
    toast.promise(
      update(), 
      {
        pending: 'Sletter produkt',
        success: 'Produkt slettet',
        error: 'Noe gikk galt'
      }
    )
    closeModal()
  }

  const handleToggleArchived = async () => {
    setShowArchived(!showArchived) 
  }

  return (
    <div className="MainBody Products">
      <Modal 
        isOpen={showModal}
        onRequestClose={closeModal}
      >
        <div className="ProductsModalInner">
          <h1>Legg til Produkt</h1>
          <div className="form">
            <Input type="text" placeholder="Produktnavn" value={name} onChange={(e) => setName(e.target.value)} />
            <label>Kategori</label>
            <select value={category} onChange={(e) => setCategory(e.target.value)}>
              <option value="">-</option>
              <option value="Isolasjon">Isolasjon</option>
              <option value="Festemiddel">Festemiddel</option>
              <option value="Sluk">Sluk</option>
              <option value="Slukpakke">Slukpakke</option>
              <option value="Rørdetalj">Rørdetalj</option>
              <option value="Brannseksjonering">Brannseksjonering</option>
              <option value="Tekking">Tekking</option>
              <option value="Parapet">Parapet</option>
            </select>
            <Input type="number" placeholder="Produktkost/enhet" value={cost} onChange={(e) => setCost(e.target.value)} />
            <Input type="number" placeholder="Arbeidskost/enhet" value={workcost} onChange={(e) => setWorkCost(e.target.value)} />
            <Input type="number" placeholder="Tykkelse (mm)" value={transportcost} onChange={(e) => setTransportCost(e.target.value)} />
            <label>Leverandør</label>
            <select value={supplier} onChange={(e) => setSupplier(e.target.value)}>
              <option value="">-</option>
              <option value="protan">Protan</option>
              <option value="glava">Rockwool</option>
              <option value="sunde">Sunde</option>
            </select>
            <div className="checkbox">
              <input type="checkbox" placeholder="Arkivert" checked={archived} onChange={(e) => setArchived(e.target.checked)} />
              <label>Arkivert</label>
            </div>
          </div>
          <div className="buttons">
            <button onClick={closeModal}>Avbryt</button>
            <div>
            { !adding &&
            <button className="delete" onClick={handleDeleteProduct}>Slett</button>
            }
            <button onClick={adding ? handleCreateProduct : handleUpdateProduct}>Lagre</button>
            </div>
          </div>
        </div>
      </Modal>
      <div className="top">
        <h1>Produkter</h1>
        <div className="tariff">
          <div><label>{`Arbeidskost tariff:`}</label><span>{`${config?.tariff} kr/time`}</span></div>
          <div><label>{`Fraktkost basis Sunde:`}</label><span>{`${config?.transport_base_sunde} kr/m3`}</span></div>
          <div><label>{`Fraktkost basis Protan:`}</label><span>{`${config?.transport_base_protan} kr/m3`}</span></div>
          <div><label>{`Fraktkost basis Rockwool:`}</label><span>{`${config?.transport_base_rockwool} kr/m3`}</span></div>
        </div>
        <div className="buttons">
          <button className={showArchived ? 'selected' : ''} onClick={handleToggleArchived}>Arkiv</button>
          <button onClick={handleAddProduct}>Legg til</button>
        </div>
      </div>
      <div className="productTable">
       <table {...getTableProps()}>
         <thead>
           {// Loop over the header rows
           headerGroups.map(headerGroup => (
             // Apply the header row props
             <tr {...headerGroup.getHeaderGroupProps()}>
               {// Loop over the headers in each row
               headerGroup.headers.map(column => (
                 // Apply the header cell props
                 <th {...column.getHeaderProps()}>
                   {// Render the header
                   column.render('Header')}
                 </th>
               ))}
             </tr>
           ))}
         </thead>
         {/* Apply the table body props */}
         <tbody {...getTableBodyProps()}>
           {// Loop over the table rows
           rows.map(row => {
             // Prepare the row for display
             prepareRow(row)
             return (
               // Apply the row props
               <tr {...row.getRowProps()} onClick={() => handleEditProduct(row.original)}>
                 {// Loop over the rows cells
                 row.cells.map(cell => {
                   // Apply the cell props
                   return (
                     <td {...cell.getCellProps()}>
                       {// Render the cell contents
                       cell.render('Cell')}
                     </td>
                   )
                 })}
               </tr>
             )
           })}
         </tbody>
       </table>
      </div>
    </div>
  )
}
